@import "../../../styles/variables.module";
.feature {
  .featureBox {
    width: calc(50% - 30px);
    display: inline-flex;
    margin-right: 30px;
    background: $white-color;
    border-radius: 10px;
    padding: 24px;
    margin-bottom: 30px;
    position: relative;
    min-height: 254px;
    align-items: flex-start;
    @include extra_large {
      width: calc(100% / 2 - 15px);
      margin-right: 15px;
      margin-bottom: 15px;
      padding: 15px;
    }
    @include large_device {
      min-height: auto;
    }
    @include mobile_device {
      width: 100%;
      margin-right: 0;
    }
    .featureImg {
      display: flex;
      justify-content: center;
      width: 40px;
      height: 40px;
      background: $secondary-color;
      text-align: center;
      line-height: 40px;
      border-radius: 50px;
      -webkit-box-shadow: 0 4px 10px rgba(21, 42, 65, 0.08);
      box-shadow: 0 4px 10px rgba(21, 42, 65, 0.08);
      flex: 0 0 auto;
    }
    .bedge {
      position: relative;
      right: 0;
      background: $theme-color;
      color: $secondary-color;
      text-transform: uppercase;
      writing-mode: vertical-rl;
      top: -24px;
      font-weight: 500;
      font-size: 10px;
      line-height: 10px;
      padding: 5px 5px 20px 5px;
      -webkit-text-orientation: upright;
      z-index: 1;
      order: 1;
      margin-left: 5px;
      @include extra_large {
        letter-spacing: -1.5px;
        top: -15px;
      }
      @include large_device {
        line-height: 8px;
        padding: 3px 4px 16px 4px;
        letter-spacing: -2px;
      }
      &:before {
        content: "";
        position: absolute;
        width: 16px;
        height: 16px;
        background: $white-color;
        bottom: -7px;
        left: 2px;
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
        z-index: 0;
        @include large_device {
          bottom: -7px;
          left: 0;
        }
      }
    }
    .detail {
      width: calc(100% - 70px);
      overflow: hidden;
      margin-left: 18px;
      position: relative;
      @include extra_large {
        width: calc(100% - 50px);
        margin-left: 15px;
      }
      .title {
        font-size: 20px;
        color: $theme-custom-color;
        text-transform: capitalize;
        font-weight: 700;
        margin-bottom: 10px;
        line-height: 28px;
        @include extra_large {
          font-size: 16px;
          margin-bottom: 8px;
        }
        @include mobile_device {
          margin-bottom: 5px;
        }
      }
      .desc {
        width: 100%;
        margin-bottom: 0;
        position: relative;
        z-index: 2;
        @include large_device {
          font-size: 14px;
        }
      }
    }
  }
  .rightDetails {
    background: $white-color;
    border-radius: 10px;
    text-align: center;
    padding-bottom: 32px;
    position: relative;
    width: 350px;
    margin: 0 auto;
    height: fit-content;
    @include extra_large {
      width: 280px;
    }
    @include extra_small {
      width: 280px;
    }
    &::before {
      content: "";
      background-image: url("../../" + $bg-base-path + "/dot-4.svg");
      width: 105px;
      height: 95px;
      bottom: -45px;
      right: -34px;
      position: absolute;
      background-repeat: no-repeat;
      z-index: -1;
      @include extra_large_device {
        right: -20px;
      }
      @include large_device {
        content: none;
      }
    }
    .btn {
      width: 80%;
      padding: 15px 24px;
      line-height: 22px;
      @include mobile_device {
        width: 90%;
      }
    }
    .title {
      color: $white-color;
      font-weight: 700;
      font-size: 20px;
      padding: 0 40px;
      text-align: center;
      position: relative;
      margin-bottom: 0;
      z-index: 2;
      height: 76px;
      line-height: 30px;
      display: flex;
      align-items: center;
      @include extra_large {
        font-size: 18px;
        height: 60px;
        line-height: 24px;
      }
      @include extra_small {
        font-size: 16px;
        line-height: 22px;
        height: 60px;
      }
      .success {
        color: #04be5b;
        text-decoration: line-through;
      }
      &::before {
        content: "";
        background-image: url("../../" + $bg-base-path + "/b-bg.svg");
        width: 100%;
        display: inline-block;
        height: 76px;
        background-repeat: no-repeat;
        background-size: contain;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        z-index: -1;
      }
    }
    .boxList,
    .boxTable {
      margin-top: 32px;
      text-align: left;
      margin-left: 50px;
      margin-bottom: 32px;
      li,
      td {
        color: $theme-color;
        font-weight: 500;
        font-size: 17px;
        line-height: 23px;
        margin-bottom: 16px;
        position: relative;
        @include large_device {
          font-size: 14px;
          margin-bottom: 10px;
        }
        &::before {
          content: "";
          background-image: url("../../" + $bg-base-path + "/tick.svg");
          width: 24px;
          height: 24px;
          background-size: cover;
          display: inline-block;
          position: absolute;
          left: -40px;
          top: -2px;
        }
      }
      td {
        padding: 0 0 16px;
        @include large_device {
          padding-bottom: 10px;
        }
        .imgsIocn {
          position: absolute;
          left: -40px;
          top: 0;
          font-size: 19px;
        }
        p {
          line-height: inherit;
          color: inherit;
          margin: 0;
        }
        &::before {
          content: initial;
        }
      }
      tr:last-child td {
        padding-bottom: 0 !important;
      }
      &.boxTable td {
        .imgsIocn {
          position: absolute;
          left: -40px;
          top: 0;
          font-size: 19px;
        }
        p {
          line-height: inherit;
          color: inherit;
          margin: 0;
        }
        &::before {
          content: initial;
        }
      }
    }
    .boxList {
      li {
        @include large_device {
          font-size: 14px;
          &::before {
            top: 2px;
          }
        }
      }
    }
  }
}
